import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import ProjectLink from "../components/ProjectLink"

const PartnerPage = ({ data }) => {
  const { partnerQuery, projectsQuery } = data
  const partner = partnerQuery["frontmatter"]
  const projects = projectsQuery["edges"]

  const imageStyle = { borderRadius: "10px", maxWidth: 300 }

  return (
    <Layout>
      <div>Partner: {partner.name}</div>
      <Img style={imageStyle} fluid={partner.image.childImageSharp.fluid} />
      <div>Projects: </div>
      {projects &&
        projects.map((project, i) => (
          <ProjectLink key={i} project={project.node} />
        ))}
    </Layout>
  )
}

export default PartnerPage

// String! implies non-nullable
// We are guaranteed an id because once was assigned on page creation
export const pageQuery = graphql`
  query PartnerByID($id: String!, $projects: [String]) {
    partnerQuery: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        name
        projects
        image {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    projectsQuery: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "project-page" }
          name: { in: $projects }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            name
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
