import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const ProjectLink = ({ project }) => {
  return (
    <div>
      <Link to={project.fields.slug}>
        <div className= "px-6 py-2">
          
          <div>
            <ProjectImage image={project.frontmatter.image}/>

          </div>
          <div className="font-bold text-xl mb-2"> {project.frontmatter.name} </div>
          <p className="text-gray-700 text-base">
            {project.frontmatter.description}
          </p>
        </div>
      </Link>
    </div>
  )
}

export default ProjectLink

const ProjectImage = ({ image }) => {
  const imageStyle = {
    width: 400,
    height: 300,
    overflow: "hidden",
  }
  return <Img style={imageStyle} fluid={image.childImageSharp.fluid} />
}